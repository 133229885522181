import { useMutation } from '@tanstack/react-query';

import {
  type SubscriptionProvider,
  useUpdateAuthState,
} from '~/store/AuthStore';
import type { HttpUrl } from '~/utils/@types/HttpUrl';
import { debugError } from '~/utils/debugError';
import { useClientUrl } from '~/utils/root';

interface RefreshAuthReturn {
  refreshAuth: () => void;
}

export function useRefreshAuth(): RefreshAuthReturn {
  const clientUrl = useClientUrl();
  const updateAuthState = useUpdateAuthState();

  const { mutate } = useMutation<ServerResponse>({
    mutationKey: ['sync-auth'],
    mutationFn: async () => {
      const data = await fetchApi({ clientUrl });
      if (data) {
        const { isPremium, firebaseUid, subscriptionProvider } = data;
        updateAuthState({ isPremium, firebaseUid, subscriptionProvider });
      }
      return data;
    },
  });

  return { refreshAuth: mutate };
}

/* Fetching */
interface FetchConfig {
  clientUrl: HttpUrl;
}

interface ServerResponse {
  isPremium: boolean;
  firebaseUid: string | null;
  subscriptionProvider: SubscriptionProvider | null;
}

async function fetchApi({ clientUrl }: FetchConfig): Promise<ServerResponse> {
  const url = new URL(`${clientUrl}/sync-auth`);
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    const responseText = await response.text();
    debugError(responseText);
    throw new Error('Failed to fetch subscription plans');
  }

  return response.json();
}
